@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: logoFont;
  src: url(fonts/BURN-RUBBER.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body {
  color: #1f1f1f;
  background-color: #f2fcfb;
  background-image: url(content/concrete.jpeg);
}

.logoCustom {
  font-size: 150px;
  font-family: logoFont;
}


.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(content/film-grain-overlay.jpg);
  mix-blend-mode: multiply;
  pointer-events: none;
}
